import TableUpdateFormItem from '../tableUpdateFormItem/tableUpdateFormItem'
import { TableFormAccordingProps } from './tableUpdateFormAccording.props'

const TableFormAccording = (props: TableFormAccordingProps) => {
  const { list, datasetId, isSchema } = props

  return (
    <TableUpdateFormItem
      datasetId={datasetId}
      isSchema={isSchema}
      item={isSchema ? list : list[0]}
      tableIndex={0}
    />
  )
}

export default TableFormAccording
