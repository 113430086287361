import PageHeader from '@/components/pageHeader/pageHeader'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useTrans } from '@/hooks/useTranslation'

import useFormDatasetUpdate, {
  useRemoveDataset,
  useValidateLeavePage,
} from './datasetUpdateLayout.hook'
import { DatasetUpdateLayoutProps } from './datasetUpdateLayout.props'

const DatasetUpdateLayout = (props: DatasetUpdateLayoutProps) => {
  const {
    name,
    description,
    extra,
    cbSubmit,
    children,
    schema,
    defaultValues,
  } = props
  const { t } = useTrans()

  const { onSubmit, handleCancel, form, isView, isDisabled, setRefresh } =
    useFormDatasetUpdate({
      cbSubmit,
      schema,
      defaultValues,
    })

  useValidateLeavePage({ isView })

  const { handleRemoveDataset } = useRemoveDataset()

  return (
    <Form {...form}>
      <PageHeader
        extra={extra}
        haveSubSidebar
        subTitle={description || ''}
        title={name || ''}
      >
        <form className="w-full pb-4" onSubmit={onSubmit}>
          <div className="flex justify-end items-center">
            <div className="grow flex justify-end items-center">
              <Button
                className="!border-none !shadow-none !bg-transparent !text-secondary-1"
                disabled={isDisabled}
                onClick={handleRemoveDataset}
                variant={'outline'}
              >
                {t('titleDeleteDataset')}
              </Button>
              {isView && (
                <Button
                  disabled={isDisabled}
                  onClick={() => setRefresh(false)}
                  variant="default"
                >
                  {t('refreshDataset')}
                </Button>
              )}
              {!isView && (
                <>
                  <Button
                    className="!border-none !shadow-none !bg-transparent !text-secondary-1"
                    onClick={() => {
                      handleCancel()
                    }}
                    variant={'outline'}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    className="min-w-[80px]"
                    disabled={isDisabled}
                    onClick={() => setRefresh(true)}
                    type="submit"
                    variant="default"
                  >
                    {t('save')}
                  </Button>
                </>
              )}
            </div>
          </div>
          {children({ form })}
        </form>
      </PageHeader>
    </Form>
  )
}

export default DatasetUpdateLayout
