import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { useTrans } from '@/hooks/useTranslation'
import { RowCheckbox } from '@/pages/dataWarehouse/components'

import { TableUpdateFormAccording } from './components'
import { useTableUpdateFormFactoryContext } from './tableUpdateFormFactory.context'
import { TableUpdateFormFactoryProps } from './tableUpdateFormFactory.props'

const TableUpdateFormFactory = (props: TableUpdateFormFactoryProps) => {
  const { item, index } = props
  const { t } = useTrans()
  const { isCheckedAll, handleUpdateAllColumnsChecked } =
    useTableUpdateFormFactoryContext()
  const processedId = item?.input_id

  return (
    <AccordionItem
      className="mb-6"
      key={`${item.name}.${index}`}
      value={`${item.name}.${index}`}
    >
      <AccordionTrigger>
        <div className="w-full flex justify-between">
          <p>
            {t('tableName')}: {item.name}
          </p>
          <div className="mr-2">
            <RowCheckbox
              checked={isCheckedAll[processedId]}
              className="!p-0"
              isRowSelectALL={true}
              label={t('checkAll')}
              onChange={(checked) => {
                handleUpdateAllColumnsChecked(processedId, 0, checked)
              }}
            />
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <TableUpdateFormAccording datasetId={processedId} list={item.tables} />
      </AccordionContent>
    </AccordionItem>
  )
}

export default TableUpdateFormFactory
