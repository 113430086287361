import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { confirmRodal } from '@/components/rodal'
import Rodal from '@/components/rodal/rodal'
import { BASE_URL } from '@/constants/common'
import { useSession } from '@/contexts/sessionContext'
import {
  clearAuth,
  getCookie,
  isAuth,
  signout,
  updateUser,
} from '@/helpers/auth'
import ConsoleHelper from '@/helpers/ConsoleHelper'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import axios from '@/services/http/axiosInstance'

import { userSettingValidate } from './userSetting.validate'

const useUserSetting = () => {
  const { t } = useTrans()
  const [isLoading, setLoading] = useState(true)
  const { navigate } = useRouter()
  const { schema } = userSettingValidate()
  const form = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    const loadProfile = () => {
      const token = getCookie('token')
      axios
        .get(`${BASE_URL}/backend/user/${isAuth()._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          ConsoleHelper('New Settings ', res.data)
          form.setValue('name', res?.data?.name || '', {
            shouldValidate: true,
          })
          form.setValue('company', res?.data?.company || '', {
            shouldValidate: true,
          })
          form.setValue('email', res?.data?.email || '', {
            shouldValidate: true,
          })
        })
        .catch((err) => {
          toast.error(`Error To Your Information ${err.response.statusText}`)
          if (err.response.status === 401) {
            signout(() => {
              navigate('/login')
            })
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
    loadProfile()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = form.handleSubmit(async (payload) => {
    const { email, company, name } = payload
    const token = getCookie('token')
    axios
      .put(
        `${BASE_URL}/backend/user/update`,
        {
          email,
          name,
          company,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        updateUser(res, () => {
          toast.success('Profile Updated Successfully')
        })
      })
      .catch((err) => {
        if (err.response.data.errors) toast.error(err.response.data.errors)
        else if (err.response) {
          toast.error(
            t('serverConnectionError') +
              err.response.status +
              ' ' +
              err.response.statusText,
          )
        } else {
          toast.error(t('serverConnectionErrorSupport'))
        }
      })
  })

  const onGoBack = () => {
    navigate(-1)
  }

  return { t, isLoading, form, onSubmit, onGoBack }
}

export const useDeleteAccountUser = () => {
  const { t } = useTrans()
  const { email } = isAuth()
  const { setSession } = useSession()
  const { navigate } = useRouter()

  const handleDeleteAccount = () => {
    confirmRodal({
      children: ({ onClose }) => {
        return (
          <Rodal
            message={t('deleteAccountPopupMessage')}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={() => {
              axios
                .delete(`${BASE_URL}/backend/user/${email}`, {
                  headers: {
                    Authorization: `Bearer ${getCookie('token')}`,
                  },
                })
                .then(() => {
                  clearAuth()
                  toast.success(t('accountDeletedSuccessfully'))
                  setSession(false)
                  navigate('/login')
                })
                .catch((err) => {
                  if (err.response)
                    if (err.response.data)
                      if (err.response.data.errors)
                        toast.error(err.response.data.errors)
                      else
                        toast.error(
                          t('serverConnectionError') +
                            err.response.status +
                            ' ' +
                            err.response.statusText,
                        )
                    else
                      toast.error(
                        t('serverConnectionError') +
                          err.response.status +
                          ' ' +
                          err.response.statusText,
                      )
                  else toast.error(t('serverConnectionErrorSupport'))
                })
            }}
            title={t('deleteAccountPopupTitle')}
          />
        )
      },
    })
  }

  return { handleDeleteAccount }
}

export default useUserSetting
