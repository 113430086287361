import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { QR_URL_DEV, QR_URL_PROD, tiersOptions } from '@/constants/common'
import { deployProject } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { useWarehouseSomeStatus } from '@/hooks/useWarehouseSomeStatus/useWarehouseSomeStatus'
import { ANY, IProjectConfigurationData, ISubscription } from '@/types'

import { listStatusWarehouseToDeploy } from './goLive.constant'

export const useWhatsappOutputChannel = (outputChannels: ANY) => {
  const channel = 'whatsapp'
  const activateWhatsapp = outputChannels?.[channel]?.activateWhatsapp

  return {
    userWhatsappSize: outputChannels?.[channel]?.phoneNumbers?.length ?? 0,
    activateWhatsapp,
  }
}

export const useTeramotOutputChannel = (outputChannels: ANY) => {
  const channel = 'teramot'

  const activateTeramot = outputChannels[channel]?.activateTeramot

  return {
    usersTeramotSize: outputChannels?.[channel]?.emails?.length ?? 0,
    activateTeramot,
  }
}

const useGoLive = () => {
  const dispatch = useAppDispatch()
  const { status: statusSomeWarehouseAvl } = useWarehouseSomeStatus(
    listStatusWarehouseToDeploy,
  )

  const [readyToSubmit, setReadyToSubmit] = useState(true)

  const [isRefreshChecked, setIsRefreshChecked] = useState<boolean>(false)

  const stage = window.location.toString().includes('staging')
    ? 'staging'
    : 'server'
  const { t } = useTrans()
  const { params } = useRouter()
  const pid = params.pid!

  const { project } = useGetProject({ pid })

  const subscription = useMemo<ISubscription>(
    () =>
      project?.subscription ? project.subscription : ({} as ISubscription),
    [project?.subscription],
  )
  const outputChannels = useMemo(
    () => (project?.outputChannels ? project.outputChannels : {}),
    [project?.outputChannels],
  )
  const configuration = useMemo<IProjectConfigurationData>(
    () =>
      project?.configuration
        ? project.configuration
        : ({} as IProjectConfigurationData),
    [project?.configuration],
  )

  const { activateWhatsapp, userWhatsappSize } =
    useWhatsappOutputChannel(outputChannels)
  const { activateTeramot, usersTeramotSize } =
    useTeramotOutputChannel(outputChannels)

  let users = 0
  activateWhatsapp ? (users += userWhatsappSize) : 0
  activateTeramot ? (users += usersTeramotSize) : 0

  const isActiveSubscription =
    subscription?.status === 'active' || subscription?.status === 'trialing'

  const inputs = project?.inputData ? Object.keys(project?.inputData).length : 0

  let usersLimit = 0
  let inputsLimit = 0
  if (subscription?.tierName)
    usersLimit =
      tiersOptions.find((item) =>
        [item.key, item.name].includes(subscription.tierName),
      )?.usersLimit || 0
  if (subscription?.tierName)
    inputsLimit =
      tiersOptions.find((item) =>
        [item.key, item.name].includes(subscription.tierName),
      )?.inputLimit || 0

  useEffect(() => {
    let ready = project?.needDeploy !== undefined ? project.needDeploy : true
    if (users > usersLimit) ready = false
    if (inputs > inputsLimit) ready = false
    if (!subscription?.tierName || !subscription?.status) ready = false
    if (!isActiveSubscription) ready = false
    if (users === 0) ready = false
    if (!statusSomeWarehouseAvl) ready = false
    setReadyToSubmit(ready)
  }, [
    users,
    inputs,
    inputsLimit,
    isActiveSubscription,
    project?.needDeploy,
    subscription,
    usersLimit,
    statusSomeWarehouseAvl,
  ])

  const handleSubmit = () => {
    const promise = dispatch(deployProject(project.pid, isRefreshChecked))
    toast.promise(promise, {
      pending: t('deploymentStatusProcessing'),
      success: t('deploymentStatusSuccess'),
      error: t('deploymentStatusError'),
    })
  }

  const QRCode = stage === 'server' ? QR_URL_PROD : QR_URL_DEV

  return {
    t,
    subscription,
    isActiveSubscription,
    outputChannels,
    stage,
    activateWhatsapp,
    usersLimit,
    inputs,
    inputsLimit,
    project,
    configuration,
    readyToSubmit,
    handleSubmit,
    QRCode,
    activateTeramot,
    users,
    statusSomeWarehouseAvl,
    isRefreshChecked,
    setIsRefreshChecked,
  }
}

export default useGoLive
