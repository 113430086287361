import { Outlet } from 'react-router'

import { SidebarLabelItem } from '@/components/sidebarLabelItem'
import Spin from '@/components/spin/spin'
import SubSidebar from '@/components/subSidebar/subSidebar'
import { useTrans } from '@/hooks/useTranslation'
import { useWarehouseCreationEnabled } from '@/hooks/useWarehouseCreationEnabled/useWarehouseCreationEnabled'

import { useDataWarehouse } from './dataWarehouse.hook'

const DataWarehouse = () => {
  const { t } = useTrans()
  const { warehouses, ref_scrollElement, goToCreateDataWarehouse, loading } =
    useDataWarehouse()
  const { isDataWarehouseEnabled, isWarehouseLimitReached } =
    useWarehouseCreationEnabled()

  return (
    <div className="w-full grow flex h-full">
      <SubSidebar
        addButton={{
          label: t('addTable'),
          onClick: goToCreateDataWarehouse,
          disable: !isDataWarehouseEnabled || isWarehouseLimitReached,
        }}
        buttons={warehouses}
        renderLabel={(item, isActive) => (
          <SidebarLabelItem isActive={isActive} item={item} />
        )}
        title={t('table')}
      />
      <div className="w-full" ref={ref_scrollElement}>
        {loading ? (
          <div className="grow h-full flex justify-center items-center">
            <Spin />
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  )
}

export default DataWarehouse
