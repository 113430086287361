import { EETLStatus } from '@/types'

import { useGetCurrentProject } from '../useGetCurrentProject/useGetCurrentProject'
import { useGetProject } from '../useGetProject/useGetProject'

export const useWarehouseCreationEnabled = () => {
  const { pid } = useGetCurrentProject()

  const { project } = useGetProject({ pid: pid! })

  const isDataWarehouseEnabled = project?.inputData
    ? Object.values(project.inputData).some(
        (data) => data.etl?.status === EETLStatus.SUCCESSFUL,
      )
    : false

  const isWarehouseLimitReached = (project?.warehouse?.length ?? 0) >= 4

  return {
    isDataWarehouseEnabled,
    isWarehouseLimitReached,
  }
}
