/* eslint-disable no-unused-vars */
import { CheckedState } from '@radix-ui/react-checkbox'
import React, { ReactNode, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { browserCacheKey, MAXIMUM_SELECT_HEADER } from '@/constants/common'
import useBrowserCache from '@/hooks/useBrowserCache'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { IIsCheckedAll } from '@/pages/dataWarehouse/dataWarehouseCreate/dataWarehouseCreate.props'
import { ANY, IProjectInputDataWithInputId } from '@/types'

interface TableUpdateFormFactoryCtxProps {
  isCheckedAll: IIsCheckedAll
  handleUpdateAllColumnsChecked: (
    datasetId: string,
    tableIndex: number,
    checked: CheckedState,
  ) => void
  handleUpdateColumnsChecked: (
    datasetId: string,
    tableIndex: number,
    colName: string,
    checked: boolean,
  ) => void
}

export const TableUpdateFormFactoryCtx =
  React.createContext<TableUpdateFormFactoryCtxProps | null>(null)

export const countSelectedColumns = (
  columns: Array<string>,
  selectedColumns: Record<string, boolean>,
) => {
  return columns.reduce((count: number, col: string) => {
    return selectedColumns?.[col] ? count + 1 : count
  }, 0)
}

export const TableUpdateFormFactoryProvider = ({
  children,
  form,
  dataSource = 'datasets',
}: {
  children: ReactNode
  form: ANY
  dataSource?: 'datasets' | 'sources' | 'goldTable'
}) => {
  const { t } = useTrans()
  const { params } = useRouter()
  const { pid, id } = params

  const datasets: IProjectInputDataWithInputId[] = form.getValues(dataSource)
  const { insertOrUpdateCacheData } = useBrowserCache(browserCacheKey.tables)

  const [isCheckedAll, setIsCheckedAll] = useState<IIsCheckedAll>({})

  useEffect(() => {
    const isCheckedAll: IIsCheckedAll = {}
    if (!datasets || !Array.isArray(datasets) || datasets.length === 0) {
      setIsCheckedAll(isCheckedAll)
      return
    }

    datasets.forEach((dataset: IProjectInputDataWithInputId) => {
      const tables = dataset.tables
      if (!tables) return
      if (Array.isArray(tables) && tables.length === 0) return
      const firstTable = tables[0]
      const columns = firstTable.columns
      const selectedColumns = firstTable?.selectedColumns ?? {}
      const countSelectedCol = countSelectedColumns(columns, selectedColumns)
      const keySelectedAll: string = `${dataset.input_id}`
      isCheckedAll[keySelectedAll] =
        countSelectedCol === columns.length ||
        countSelectedCol === MAXIMUM_SELECT_HEADER
    })
    setIsCheckedAll(isCheckedAll)
  }, [JSON.stringify(datasets)])

  const updateCacheWarehouseWhenSourceChange = (
    data: IProjectInputDataWithInputId[],
  ) => {
    if (dataSource === 'sources') {
      insertOrUpdateCacheData({
        where: {
          pid: pid,
          warehouseId: id,
          source: 'update_basic',
        },
        data: {
          [dataSource]: data,
        },
      })
    }
  }

  const handleUpdateAllColumnsChecked = (
    datasetId: string,
    tableIndex: number,
    checked: CheckedState,
  ) => {
    const datasets = form.getValues(dataSource)
    const dataset = datasets.find((d: ANY) => d.input_id === datasetId)

    const tables = dataset.tables

    const table = tables[tableIndex]

    const columns = table.columns
    const selectedColumns: Record<string, boolean> = table.selectedColumns
    const countSelectedCol = countSelectedColumns(columns, selectedColumns)

    let avlCountCheck = MAXIMUM_SELECT_HEADER - countSelectedCol
    const newSelectedColumns = columns.reduce(
      (acc: Record<string, boolean>, col: string) => {
        if (checked) {
          if (avlCountCheck > 0) {
            acc[col] = true
            avlCountCheck--
            return acc
          }
          return acc
        } else {
          acc[col] = false
          return acc
        }
      },
      {
        ...selectedColumns,
      },
    )

    tables[tableIndex].selectedColumns = newSelectedColumns
    const newDataSet = datasets.map((d: ANY) => {
      if (d.input_id === datasetId) {
        return {
          ...d,
          tables,
        }
      }
      return d
    })
    form.setValue(dataSource, newDataSet, { shouldDirty: true })
    // If dataSources is sources in case update screen
    updateCacheWarehouseWhenSourceChange(newDataSet)

    const keySelectedAll: string = `${datasetId}_${tableIndex}`

    setIsCheckedAll({ ...isCheckedAll, [keySelectedAll]: checked as boolean })
  }

  const handleUpdateColumnsChecked = (
    datasetId: string,
    tableIndex: number,
    colName: string,
    checked: boolean,
  ) => {
    const datasets = form.getValues(dataSource)
    const dataset = datasets.find((d: ANY) => d.input_id === datasetId)

    const tables = dataset.tables

    const table = tables[tableIndex]

    const columns = table.columns
    const selectedColumns = table?.selectedColumns ?? {}
    const keySelectedAll: string = `${datasetId}_${tableIndex}`

    const countSelectedCol = countSelectedColumns(columns, selectedColumns)
    if (
      selectedColumns[colName] === false &&
      countSelectedCol >= MAXIMUM_SELECT_HEADER
    ) {
      toast.warning(
        t('limitSelectedInputData', { limit: MAXIMUM_SELECT_HEADER }),
      )

      if (!isCheckedAll[keySelectedAll]) {
        isCheckedAll[keySelectedAll] = true
        setIsCheckedAll({ ...isCheckedAll })
      }

      return
    }

    selectedColumns[colName] = checked

    if (checked) {
      if (
        countSelectedCol >= MAXIMUM_SELECT_HEADER - 1 ||
        countSelectedCol === columns.length - 1
      ) {
        // setIsCheckedAll(true)
        isCheckedAll[keySelectedAll] = true
        setIsCheckedAll({ ...isCheckedAll })
        tables[tableIndex].selectedColumns = selectedColumns

        const newDataSet = datasets.map((d: ANY) => {
          if (d.input_id === datasetId) {
            return {
              ...d,
              tables,
            }
          }
          return d
        })
        form.setValue(dataSource, newDataSet, { shouldDirty: true })
        // If dataSources is sources in case update screen
        updateCacheWarehouseWhenSourceChange(newDataSet)
        return
      }
    }

    if (
      !checked &&
      countSelectedCol - 1 < MAXIMUM_SELECT_HEADER &&
      isCheckedAll[keySelectedAll]
    ) {
      // checked is False
      // countSelectedCol - 1 Because we need minus 1 for the current column
      // isCheckedAll[tableIndex] is true
      isCheckedAll[keySelectedAll] = false
      setIsCheckedAll({ ...isCheckedAll })
    }

    // update selectedColumns
    tables[tableIndex].selectedColumns = selectedColumns

    const newDataSet = datasets.map((d: ANY) => {
      if (d.input_id === datasetId) {
        return {
          ...d,
          tables,
        }
      }
      return d
    })
    form.setValue(dataSource, newDataSet, { shouldDirty: true })
    updateCacheWarehouseWhenSourceChange(newDataSet)
  }

  return (
    <TableUpdateFormFactoryCtx.Provider
      value={{
        isCheckedAll,
        handleUpdateAllColumnsChecked,
        handleUpdateColumnsChecked,
      }}
    >
      {children}
    </TableUpdateFormFactoryCtx.Provider>
  )
}

export const useTableUpdateFormFactoryContext = () => {
  const ctx = React.useContext(TableUpdateFormFactoryCtx)
  if (!ctx)
    throw new Error(
      'TableUpdateFormFactoryCtx must be used within a TableUpdateFormFactoryContext',
    )

  return ctx
}

export default TableUpdateFormFactoryCtx
