import { useTranslation } from 'react-i18next'

import yup from '@/validates'

export const useResetPasswordSchema = () => {
  const { t } = useTranslation()

  return {
    schema: yup.object().shape({
      password1: yup
        .string()
        .required(t('requiredPassword'))
        .min(8, t('passwordLength')),
      password2: yup
        .string()
        .oneOf([yup.ref('password1')], t('errorMessagePasswordsDoNotMatch'))
        .required(t('requiredPassword'))
        .min(8, t('passwordLength')),
    }),
  }
}
