import { useTranslation } from 'react-i18next'

import yup from '@/validates'

export const useRegisterSchema = () => {
  const { t } = useTranslation()
  return {
    schema: yup.object().shape({
      name: yup
        .string()
        .required(t('nameRequired'))
        .min(3, t('nameLength'))
        .max(32, t('nameLength')),
      email: yup
        .string()
        .required(t('emailRequired'))
        .email(t('emailInvalid'))
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          t('emailNotValidDomain'),
        ),
      company: yup
        .string()
        .required(t('companyNameRequired'))
        .min(3, t('companyNameLength'))
        .max(32, t('companyNameLength')),
      password1: yup
        .string()
        .required(t('requiredPassword'))
        .min(8, t('passwordLength')),
      password2: yup
        .string()
        .oneOf([yup.ref('password1')], t('errorMessagePasswordsDoNotMatch'))
        .required(t('requiredPassword'))
        .min(8, t('passwordLength')),
    }),
  }
}
