import { useState } from 'react'
import { toast } from 'react-toastify'

import { setConfiguration } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useTrans } from '@/hooks/useTranslation'
import { IProject, IProjectConfigurationData } from '@/types'

interface IProps {
  pid: string
  configuration: IProjectConfigurationData
  project: IProject
}
export const useKnowledgeList = (props: IProps) => {
  const { configuration, project, pid } = props

  const { t } = useTrans()
  const dispatch = useAppDispatch()
  const [isCreate, setIsCreate] = useState(false)
  const toggleCreate = () => {
    setIsCreate((old) => !old)
  }

  const handleSaveKnowledge = () => {
    const { botName, lang } = configuration

    const warehouse = project.warehouse

    const oldKnowledge = configuration?.knowledge ?? {}
    const newKnowledge = warehouse!.reduce((t, v) => {
      const key = v.id!
      if (key && !oldKnowledge[key]) {
        return { ...t, [key]: [] }
      }
      return { ...t, [key]: oldKnowledge[key] }
    }, {})

    const oldKnowledgeGeneral = configuration?.knowledgeGeneral ?? []

    const _configuration = {
      botName,
      lang,
      knowledge: newKnowledge,
      knowledgeGeneral: oldKnowledgeGeneral,
    }

    const promise = dispatch(setConfiguration(pid, { ..._configuration }))
    toast.promise(promise, {
      pending: t('processingConfiguration'),
      success: t('configurationSetSuccessfully'),
      error: t('errorSettingConfiguringBot'),
    })
    promise.catch((e) => {
      if (e?.key) toast.error(t(e.key))
    })
  }

  return {
    dispatch,
    isCreate,
    toggleCreate,
    handleSaveKnowledge,
  }
}
