import { createContext, ReactNode, useContext, useMemo, useState } from 'react'

import { DB_CONNECT_TYPE } from '../../dataset.constant'
import { IUpdateConnectionContext } from './updateConnection.props'
import { useUpdateConnectionSchema } from './updateConnection.validate'

const UpdateConnectionContext = createContext<IUpdateConnectionContext | null>(
  null,
)

export const UpdateConnectionProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const { databaseSchema, salesforceSchema, bigQuerySchema, sqlServerSchema } =
    useUpdateConnectionSchema()
  const [currentConnectType, setConnectType] = useState<DB_CONNECT_TYPE>(
    DB_CONNECT_TYPE.POSTGRES_SQL,
  )

  const validationSchema = useMemo(() => {
    if (currentConnectType === DB_CONNECT_TYPE.BIG_QUERY) return bigQuerySchema
    if (currentConnectType === DB_CONNECT_TYPE.SALESFORCE)
      return salesforceSchema
    if (currentConnectType === DB_CONNECT_TYPE.SQL_SERVER)
      return sqlServerSchema

    return databaseSchema
  }, [
    bigQuerySchema,
    currentConnectType,
    databaseSchema,
    salesforceSchema,
    sqlServerSchema,
  ])

  const value = {
    currentConnectType,
    validationSchema,
    setConnectType,
  }

  return (
    <UpdateConnectionContext.Provider value={value}>
      {children}
    </UpdateConnectionContext.Provider>
  )
}

export const useUpdateConnectionContext = () => {
  const ctx = useContext(UpdateConnectionContext)
  if (!ctx) {
    throw new Error(
      'useUpdateConnectionContext must be used within a UpdateConnectionProvider',
    )
  }
  return { ...ctx }
}
