import { yupResolver } from '@hookform/resolvers/yup'
import { FormEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { BASE_URL } from '@/constants/common'
import { ERROR_MESSAGE } from '@/constants/errorCode'
import axios from '@/services/http/axiosInstance'
import { UserAccountValues } from '@/types'

import { useRegisterSchema } from './register.validate'

const useRegister = () => {
  const { t } = useTranslation()
  const [isAcceptPrivacy, setAcceptPrivacy] = useState(false)

  const [buttonText, setButtonText] = useState<string>(t('signUp'))

  const { schema } = useRegisterSchema()

  const form = useForm({
    resolver: yupResolver(schema),
  })

  const handleChange =
    (text: 'email' | 'company' | 'name') =>
    async (e: FormEvent<HTMLInputElement>) => {
      let value = (e.target as HTMLInputElement).value

      if (text === 'company')
        value = value.charAt(0).toUpperCase() + value.slice(1)
      if (text === 'email') value = value.toLowerCase()

      form.clearErrors(text)

      form.setValue(text, value)

      await form.trigger(text)
    }

  const handleSubmit = form.handleSubmit((values: UserAccountValues) => {
    const { name, email, company, password1, password2 } = values

    if (Object.values(values).some((value) => !value)) {
      toast.error(t('fillAllFields'))
      return
    }

    if (!isAcceptPrivacy) {
      toast.error(t('privacyTermsMessage'))
      return
    }

    if (password1 === password2) {
      setButtonText(t('submitting'))
      axios
        .post(`${BASE_URL}/backend/register`, {
          email,
          name,
          company,
          password: password1,
        })
        .then(() => {
          setButtonText(t('submitted'))
          toast.success(t('emailSentTo', { email: form.getValues('email') }))
          form.reset({
            name: '',
            email: '',
            company: '',
            password1: '',
            password2: '',
          })
        })
        .catch((err) => {
          setButtonText(t('signUp'))
          if (err.response.data.errorCode) {
            const keyMessage = ERROR_MESSAGE[err.response.data.errorCode]
            if (keyMessage) {
              toast.error(t(keyMessage, { email: email }))
            } else {
              toast.error(err.response.data.message)
            }
            toast.error(err.response.data.error)
          } else if (err.response.data.errors) {
            toast.error(err.response.data.errors)
          } else if (err.response) {
            toast.error(
              t('serverConnectionError') +
                err.response.status +
                ' ' +
                err.response.statusText,
            )
          } else {
            toast.error(t('serverConnectionErrorSupport'))
          }
        })
    } else {
      toast.error(t('errorMessagePasswordsDoNotMatch'))
    }
  })

  return {
    isAcceptPrivacy,
    buttonText,
    handleChange,
    form,
    handleSubmit,
    setAcceptPrivacy,
  }
}

export default useRegister
