import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { useTrans } from '@/hooks/useTranslation'
import { RowCheckbox } from '@/pages/dataWarehouse/components'

import { TableUpdateFormAccording } from './components'
import { useTableUpdateFormFactoryContext } from './tableUpdateFormFactory.context'
import { TableUpdateFormFactoryProps } from './tableUpdateFormFactory.props'

const TableUpdateFormFactory = (props: TableUpdateFormFactoryProps) => {
  const { item, index, isSchema } = props
  const { t } = useTrans()
  const { isCheckedAll, handleUpdateAllColumnsChecked } =
    useTableUpdateFormFactoryContext()
  const processedId = item?.input_id

  return (
    <AccordionItem
      className="mb-6"
      key={`${item.name}.${index}`}
      value={`${item.name}.${index}`}
    >
      <AccordionTrigger>
        <div
          className={
            isSchema
              ? `w-full flex flex-col items-start text-left`
              : `w-full flex justify-between`
          }
        >
          <p>
            {t('tableName')}: {item.name}
          </p>
          {isSchema && (
            <p>
              {t('description')}: {item.description}
            </p>
          )}
          <div className="mr-2">
            {!isSchema && (
              <RowCheckbox
                checked={isCheckedAll[processedId]}
                className="!p-0"
                isRowSelectALL={true}
                label={t('checkAll')}
                onChange={(checked) => {
                  handleUpdateAllColumnsChecked(processedId, 0, checked)
                }}
              />
            )}
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <TableUpdateFormAccording
          datasetId={processedId}
          isSchema={isSchema}
          list={isSchema ? item : item.tables}
        />
        {item.lastModelDescription && (
          <div className="w-full flex flex-col items-start pr-4">
            <p className="text-left text-sm">
              <span className="font-semibold">
                {t('lastModelDescription')}:
              </span>{' '}
              {item.lastModelDescription}
            </p>
          </div>
        )}
      </AccordionContent>
    </AccordionItem>
  )
}

export default TableUpdateFormFactory
