import PageHeader from '@/components/pageHeader/pageHeader'
import Spin from '@/components/spin/spin'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useTrans } from '@/hooks/useTranslation'
import { EETLStatus } from '@/types'

import {
  useFormDatasetUpdate,
  useRemoveDataset,
} from './dataWarehouseUpdateLayout.hook'
import { DataWarehouseUpdateLayoutProps } from './dataWarehouseUpdateLayout.props'

const DataWarehouseUpdateLayout = (props: DataWarehouseUpdateLayoutProps) => {
  const {
    name,
    description,
    extra,
    cbSubmit,
    children,
    schema,
    defaultValues,
  } = props
  const { t } = useTrans()

  const { onSubmit, form, isView, handleCancel, isDisabled, isSubmitting } =
    useFormDatasetUpdate({
      cbSubmit,
      schema,
      defaultValues,
    })

  const { handleRemoveDataset } = useRemoveDataset()

  const status = extra?.props.status ?? null

  return (
    <Form {...form}>
      <PageHeader
        extra={extra}
        haveSubSidebar
        subTitle={description || ''}
        title={name || ''}
      >
        <form className="w-full pb-4" onSubmit={onSubmit}>
          <div className="flex justify-end items-center">
            <div className="grow flex justify-end items-center">
              <Button
                className="!border-none !shadow-none !bg-transparent !text-secondary-1"
                disabled={isDisabled}
                onClick={handleRemoveDataset}
                variant={'outline'}
              >
                {t('titleDeleteTable')}
              </Button>
              {status === EETLStatus.OUTDATED && isView && (
                <Button disabled={isDisabled} variant="default">
                  {t('refreshWarehouse')}
                </Button>
              )}
              {!isView && (
                <>
                  <Button
                    className="!border-none !shadow-none !bg-transparent !text-secondary-1"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleCancel()
                    }}
                    variant={'outline'}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    className="min-w-[80px]"
                    disabled={isSubmitting || isDisabled}
                    type="submit"
                    variant={'default'}
                  >
                    {isSubmitting && <Spin className="size-4 mr-2" />}
                    {t('save')}
                  </Button>
                </>
              )}
            </div>
          </div>
          {children({ form })}
        </form>
      </PageHeader>
    </Form>
  )
}

export default DataWarehouseUpdateLayout
