import { memo } from 'react'

import { Form, FormField } from '@/components/ui/form'
import { Input } from '@/components/ui/input'

import MessageItem, { MessageSystem } from '../messageItem/messageItem'
import { MESSAGE_TYPE } from '../messageItem/messageItem.props'
import useChatbotBody from './chatbotBody.hook'
import { useConnectChatbot } from './useConnectChatbot.hook'

const ChatBotBody = () => {
  useConnectChatbot()
  const {
    t,
    isDisableChatbot,
    messages,
    ref_listMessage,
    isChatting,
    onClickSystemMessageButton,
    form,
    onSubmit,
  } = useChatbotBody()

  return (
    <>
      <div
        className="w-full h-full flex flex-col justify-end gap-4 overflow-auto"
        ref={ref_listMessage}
      >
        {isChatting && messages?.length ? (
          <div className="left-0 bottom-0 w-full h-auto max-h-full flex flex-col gap-4 pt-4 px-6">
            {messages.map((message, index) => {
              const type = message.sender
              if (type === MESSAGE_TYPE.SYSTEM) {
                return (
                  <MessageSystem
                    buttons={message.systemButtons || []}
                    key={index}
                    onClickButton={onClickSystemMessageButton}
                  />
                )
              }
              return (
                <MessageItem
                  file={message?.file}
                  key={index}
                  sender={type}
                  text={message.text as string}
                />
              )
            })}
          </div>
        ) : (
          <div className="pt-4 px-6 w-full h-full flex flex-col justify-between">
            <p className="w-[349px] text-xs text-center">
              {isDisableChatbot ? t('wellComeChat') : t('disableAppWebChat')}
            </p>
            <div className="w-full flex flex-col gap-2">
              {messages.map((message, index) => {
                return (
                  <MessageSystem
                    buttons={message.systemButtons || []}
                    fillColor
                    key={index}
                    onClickButton={onClickSystemMessageButton}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
      <div className="bg-white z-10 shadow-md px-6 py-4">
        <Form {...form}>
          <form className="w-full" onSubmit={onSubmit}>
            <FormField
              control={form.control}
              name="content"
              render={({ field }) => (
                <Input
                  autoComplete="off"
                  className="caption2"
                  placeholder={t('botPlaceHolder')}
                  required
                  type="text"
                  {...field}
                  disabled={!isDisableChatbot}
                />
              )}
            />
          </form>
        </Form>
      </div>
    </>
  )
}

export default memo(ChatBotBody)
