import { ScrollArea } from '@/components/ui/scroll-area'
import { useTrans } from '@/hooks/useTranslation'
import { RowCheckbox } from '@/pages/dataWarehouse/components'

import { useTableUpdateFormFactoryContext } from '../../tableUpdateFormFactory.context'
import { SHOW_MAX_ITEMS } from './tableUpdateFormItem.constant'
import { TableFormItemProps } from './tableUpdateFormItem.props'

const TableUpdateFormItem = (props: TableFormItemProps) => {
  const {
    item,
    tableIndex,
    datasetId,
    isHasCheckedAll = false,
    isSchema,
  } = props
  const {
    isCheckedAll,
    handleUpdateColumnsChecked,
    handleUpdateAllColumnsChecked,
  } = useTableUpdateFormFactoryContext()

  const { t } = useTrans()

  const size = item.columns?.length ?? 0

  return (
    <div>
      {!isSchema && isHasCheckedAll && (
        <RowCheckbox
          checked={isCheckedAll[`${datasetId}_${tableIndex}`]}
          className="!p-0"
          isRowSelectALL={true}
          label={t('checkAll')}
          onChange={(checked) => {
            handleUpdateAllColumnsChecked(
              datasetId,
              tableIndex,
              Boolean(checked),
            )
          }}
        />
      )}

      <div className="mt-4">
        <ScrollArea
          className="w-full"
          style={{
            // 55 is the height of the row
            // 16 is the margin between rows
            height: !(size <= SHOW_MAX_ITEMS)
              ? `${Math.min(size, SHOW_MAX_ITEMS) * 62 + (Math.min(size, SHOW_MAX_ITEMS) - 1) * 16}px`
              : 'auto',
          }}
        >
          {!isSchema ? (
            <div className="w-full flex flex-col gap-4 pr-4">
              {item.columns?.map((col: string, index: number) => {
                const checked = item?.selectedColumns?.[col] ?? false
                const dataType = item.columnDataTypes?.[col]

                const description = item?.descriptions?.[col] ?? ''
                const labelName = dataType ? `${col} (${dataType})` : col

                return (
                  <RowCheckbox
                    checked={checked}
                    description={description}
                    key={labelName + index}
                    label={labelName}
                    onChange={() => {
                      handleUpdateColumnsChecked(
                        datasetId,
                        tableIndex,
                        col,
                        !checked,
                      )
                    }}
                  />
                )
              })}
            </div>
          ) : (
            <div className="w-full pr-4">
              {item.columns?.map(
                (col: { id: string; name: string; description?: string }) => {
                  return (
                    <div
                      className="my-4 py-4 px-6 border rounded-lg border-border-1 space-y-2 w-full"
                      key={col.id}
                    >
                      <p>{col.name}</p>
                      {col.description && (
                        <p>
                          {t('description')}: {col.description}
                        </p>
                      )}
                    </div>
                  )
                },
              )}
            </div>
          )}
        </ScrollArea>
      </div>
    </div>
  )
}

export default TableUpdateFormItem
