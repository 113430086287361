/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo } from 'react'

import { getProjects } from '@/features/project/projectSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'

export const useGetDatasetDetail = () => {
  const { params } = useRouter()
  const { pid, id } = params
  const projects = useAppSelector((state) => state.project?.projects ?? {})
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getProjects({}))
  }, [dispatch])

  const project = useMemo(() => {
    if (!pid) return null
    return projects[pid]
  }, [pid, projects])

  const isEmptyProject = Boolean(project)

  const inputDataDetail = useMemo(() => {
    if (!project) return null
    if (!id) return null
    const dataset = project?.inputData ?? {}

    return dataset?.[id]
  }, [id, project])

  const meta = {
    inputDataDetail,
    isEmptyProject,
  }

  return meta
}
