import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { BASE_URL } from '@/constants/common'
import { useTrans } from '@/hooks/useTranslation'
import axios from '@/services/http/axiosInstance'

import { UseFormForgotPasswordProps } from './formForgotPassword.props'
import { useForgotPasswordSchema } from './formForgotPassword.validate'

const useFormForgotPassword = ({ onOpen }: UseFormForgotPasswordProps) => {
  const { t } = useTrans()

  const { schema: forgotPasswordSchema } = useForgotPasswordSchema()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  })

  const onSubmit = handleSubmit(async (payload) => {
    const { email } = payload
    try {
      const url = `${BASE_URL}/backend/forgotpassword`
      const response = await axios.put(url, { email })
      if (response) {
        onOpen()
        toast.success(t('successForgotPassword'))
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.response) {
        return toast.error(t('emailNotExists'))
      }
      toast.error(t('serverConnectionErrorSupport'))
    }
  })

  return {
    isSubmitting,
    errors,
    register,
    onSubmit,
  }
}

export default useFormForgotPassword
