import React from 'react'

import { Accordion } from '@/components/ui/accordion'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import {
  TableUpdateFormFactory,
  TableUpdateFormFactoryProvider,
} from './components'
import { useUpdateBasic } from './updateBasic.hook'

const UpdateBasic = () => {
  const { t } = useTrans()
  const { form, onFormChange } = useUpdateBasic()
  return (
    <div>
      <div className="flex flex-col justify-center gap-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => {
            return (
              <FormItem className="w-full">
                <FormLabel className="caption2" requiredField>
                  {t('tableName')}
                </FormLabel>
                <FormControl>
                  <Textarea
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      onFormChange({
                        name: field.name,
                        value: e.target.value,
                      })
                    }}
                    placeholder={t('typeANameForTheTable')}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
      </div>
      <div>
        <div className="w-full overflow-y-auto">
          <TableUpdateFormFactoryProvider dataSource="sources" form={form}>
            <div className="mt-4">
              <div className="py-2">
                <FormLabel className="caption2">{t('source')}</FormLabel>
              </div>
              <FormField
                control={form.control}
                name="sources"
                render={({ field }) => {
                  if (!field.value || !field.value.length) return <div></div>
                  const _ = field.value
                  return (
                    <Accordion
                      className="w-full"
                      collapsible
                      defaultValue={'0'}
                      type="single"
                    >
                      {_.map((item: ANY, index: number) => {
                        return (
                          <TableUpdateFormFactory
                            index={index}
                            item={item}
                            key={index}
                          />
                        )
                      })}
                    </Accordion>
                  )
                }}
              />
            </div>
          </TableUpdateFormFactoryProvider>
        </div>
      </div>
    </div>
  )
}

export default React.memo(UpdateBasic)
