import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { BASE_URL } from '@/constants/common'
import { useSession } from '@/contexts/sessionContext'
import { authenticate } from '@/helpers/auth'
import ConsoleHelper from '@/helpers/ConsoleHelper'
import axios from '@/services/http/axiosInstance'
import { UserAccountValues } from '@/types'

import { useLoginSchema } from './login.validate'

type UserLoginValues = Pick<UserAccountValues, 'email' | 'password1'>

const useLogin = () => {
  const { schema } = useLoginSchema()
  const { setSession } = useSession()
  const { t } = useTranslation()

  const form = useForm({
    resolver: yupResolver(schema),
  })

  const handleSubmit = form.handleSubmit((values: UserLoginValues) => {
    const { email, password1 } = values
    if (email && password1) {
      axios
        .post(`${BASE_URL}/backend/login`, {
          email,
          password: password1,
        })
        .then((res) => {
          ConsoleHelper('Authentication Success:' + email)
          authenticate(res, () => {
            form.reset()
            setSession(true)
            toast.success(t('welcomeBack', { name: res.data.user.name }))
          })
        })
        .catch((err) => {
          form.reset({ ...values, password1: '' }, { keepDirtyValues: true })
          if (err.response)
            if (err.response.data)
              if (err.response.data.errors)
                toast.error(err.response.data.errors)
              else
                toast.error(
                  t('serverConnectionError') +
                    err.response.status +
                    ' ' +
                    err.response.statusText,
                )
            else
              toast.error(
                t('serverConnectionError') +
                  err.response.status +
                  ' ' +
                  err.response.statusText,
              )
          else toast.error(t('serverConnectionErrorSupport'))
        })
    } else {
      toast.error(t('fillAllFields'))
    }
  })

  return {
    form,
    handleSubmit,
  }
}

export default useLogin
