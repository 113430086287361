import { Outlet } from 'react-router'

import SubSidebar from '@/components/subSidebar/subSidebar'

import useTuning from './tuning.hook'

const Tuning = () => {
  const { t, subSidebarLinks } = useTuning()

  return (
    <div className="w-full grow flex h-full">
      <SubSidebar buttons={subSidebarLinks} title={t('knowledge')} />
      <Outlet />
    </div>
  )
}

export default Tuning
