import { useTranslation } from 'react-i18next'

import yup from '@/validates'

export const useLoginSchema = () => {
  const { t } = useTranslation()

  return {
    schema: yup.object().shape({
      email: yup
        .string()
        .required(t('emailRequired'))
        .email(t('emailInvalid'))
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          t('emailNotValidDomain'),
        ),
      password1: yup
        .string()
        .required(t('requiredPassword'))
        .min(8, t('passwordLength')),
    }),
  }
}
