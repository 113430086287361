import clsx from 'clsx'

import { LabelItemStatus } from '../labelItemStatus'
import { SidebarLabelItemProps } from './sidebarLabelItem.props'

const SidebarLabelItem = (props: SidebarLabelItemProps) => {
  const { item, isActive } = props

  return (
    <div className="flex flex-1 items-center justify-between gap-2 size-full overflow-hidden">
      <p
        className={clsx(
          'text-ellipsis overflow-hidden line-clamp-1 text-wrap break-all w-full text-left',
          {
            'font-bold': isActive,
          },
        )}
      >
        {item.label}
      </p>
      <div className="flex items-center h-full">
        <div className="flex flex-shrink-0 size-5">
          <LabelItemStatus status={item?.status} />
        </div>
      </div>
    </div>
  )
}

export default SidebarLabelItem
