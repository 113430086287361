import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'
import {
  TableFormFactory,
  TableFormFactoryProvider,
} from '@/pages/dataset/tableFormFactory'

import { useChangeStepUpdateDatabase } from '../updateDatabase.hook'
import { useUpdateDatabaseView } from './updateDatabaseView.hook'
import { UpdateDatabaseViewProps } from './updateDatabaseView.props'
const UpdateDatabaseView = (props: UpdateDatabaseViewProps) => {
  const { form, detail } = props
  const { t } = useTrans()
  const { handleChangeDatabase } = useChangeStepUpdateDatabase()
  useUpdateDatabaseView({ form, detail })

  return (
    <div className="w-full">
      <div className="w-full p-6 border border-slate-200 rounded-md mt-8 flex flex-col gap-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel className="font-semibold" requiredField>
                  {t('name')}
                </FormLabel>
                <FormControl>
                  <Input
                    disabled
                    {...field}
                    placeholder={t('placeholderHost')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold">
                {t('description')}
              </FormLabel>
              <FormControl>
                <Input {...field} placeholder={t('placeholderDescription')} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex">
          <div className="flex flex-col justify-end px-4">
            <Button
              className="!border-none !shadow-none !bg-transparent !text-secondary-1"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleChangeDatabase()
              }}
              variant={'outline'}
            >
              {t('changeDatabase')}
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="">
          <h4 className="h4 text-text-gray my-6">{t('columns')}</h4>
          <div className="grid gap-4">
            <TableFormFactoryProvider form={form}>
              <FormField
                control={form.control}
                name="tables"
                render={({ field }) => {
                  if (!field.value || !field.value.length) return <div></div>
                  const _ = field.value

                  return <TableFormFactory list={_} />
                }}
              />
            </TableFormFactoryProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateDatabaseView
