import { useEffect, useState } from 'react'

import { BASE_URL } from '@/constants/common'
import {
  getPlayground,
  getPlaygrounds,
  getProjects,
  setPlaygrounds,
  setSelectedPlayground,
} from '@/features/project/projectSlice'
import { isAuth } from '@/helpers/auth'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'

export const useProjectsPage = () => {
  const author = isAuth().email
  const { navigate } = useRouter()
  const [isLoading, setLoading] = useState(true)
  const projects = useAppSelector((state) => state.project?.projects ?? {})
  const playgrounds = useAppSelector(
    (state) => state.project?.playgrounds ?? [],
  )

  const isEmptyProject = Object.keys(projects).length === 0

  const dispatch = useAppDispatch()
  useEffect(() => {
    setLoading(true)
    const promise = new Promise((resolve) => {
      dispatch(getProjects({ resolve }))
    })
    promise.then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const onCreateNewProject = () => {
    dispatch(setSelectedPlayground({}))
    navigate('/project/new')
  }

  const getProjectThumbnail = (pid: string, coverFile?: string) => {
    if (!coverFile) return undefined
    return BASE_URL + '/backend/' + author + '/' + pid + '/' + coverFile
  }

  const getAllPlaygrounds = async () => {
    try {
      const playgrounds = await dispatch(getPlaygrounds)
      dispatch(setPlaygrounds(playgrounds))
    } catch (error) {
      throw new Error('Error getting playgrounds')
    }
  }

  const onNewPlaygroundClick = async (etlID: string) => {
    try {
      await dispatch(getPlayground(etlID))
      navigate('/project/new')
    } catch (error) {
      throw new Error('Error setting selected playground')
    }
  }

  const meta = {
    isLoading,
    listProject: Object.values(projects),
    projects,
    playgrounds,
    isEmptyProject,
    onCreateNewProject,
    getProjectThumbnail,
    getAllPlaygrounds,
    onNewPlaygroundClick,
  }

  return meta
}
