import { Pencil1Icon } from '@radix-ui/react-icons'

import IconTooltip from '@/components/iconTooltip'
import { onFocusInputById } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'

import { useBasicInfo } from './basicInfo.hook'
import { BasicInfoProps } from './basicInfo.props'
import BasicInfoForm from './basicInfoForm/basicInfoForm'

const BasicInfo = (props: BasicInfoProps) => {
  const { t } = useTrans()
  const { isEdit, toggleEdit } = useBasicInfo(props)
  return (
    <div className="w-full px-8 py-4 relative shadow-md rounded-md border">
      {!isEdit && (
        <div className="flex justify-end">
          <IconTooltip
            icon={
              <Pencil1Icon
                className="cursor-pointer absolute top-4 right-8"
                fontWeight={700}
                onClick={() => {
                  toggleEdit()
                  onFocusInputById('focus-botName')
                }}
              />
            }
            message={t('iconUpdateBasicInfo')}
          />
        </div>
      )}
      <div className="w-full pr-4 flex flex-col">
        {!isEdit && (
          <p>
            <span className="caption2">{t('botName')}: </span>
            <span className="text-sm">{props.botName}</span>
          </p>
        )}
        {!isEdit && (
          <p>
            <span className="caption2">{t('languageChat')}: </span>
            <span className="text-sm">{props.lang}</span>
          </p>
        )}

        {isEdit && (
          <BasicInfoForm
            IdFocus="focus-botName"
            defaultValues={{
              pid: props.pid,
              botName: props.botName,
              lang: props.lang,
            }}
            toggleEdit={toggleEdit}
          />
        )}
      </div>
    </div>
  )
}

export default BasicInfo
