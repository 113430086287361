import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'

import { createTable } from '@/features/project/projectSlice'
import { WarehouseBuildDataset } from '@/helpers/warehouseBuildDataset'
import { useAppDispatch } from '@/hooks'
import { useTrans } from '@/hooks/useTranslation'
import { useWarehouseCreationEnabled } from '@/hooks/useWarehouseCreationEnabled/useWarehouseCreationEnabled'
import { ANY, IProcessedData, IWarehouse } from '@/types'

import { DATA_WAREHOUSE_CREATE_STEP } from '../dataWarehouseCreate.constant'
import { useDataWarehouseCreateContext } from '../dataWarehouseCreate.context'
import { UseDataWarehouseCreateLayoutProps } from './dataWarehouseCreateLayout.props'

export const useDataWarehouseCreateLayout = ({
  schema,
  defaultValues,
}: UseDataWarehouseCreateLayoutProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTrans()
  const {
    pid,
    currentStep,
    warehouseTable,
    handlePreStep,
    handleUpdateCurrentDataset,
    handleNextStep,
    setWarehouseTableName,
    selectedColumnsByDataset,
    setSelectedColumnsByDataset,
  } = useDataWarehouseCreateContext()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const props: UseFormReturn<ANY, ANY, FieldValues> = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (!warehouseTable) return

    if (currentStep === DATA_WAREHOUSE_CREATE_STEP.NAME) {
      props.setValue('name', warehouseTable.name ?? '')
      props.setValue('description', warehouseTable.description ?? '')
    }
  }, [warehouseTable, currentStep, props])

  const { isDataWarehouseEnabled, isWarehouseLimitReached } =
    useWarehouseCreationEnabled()

  const onLoadWarehouseToServer = async (payload: IWarehouse) => {
    if (!pid) {
      throw new Error('Project ID is not defined')
    }

    const _payload: Record<string, unknown> = { ...payload }

    return dispatch(createTable(pid, _payload)).catch((error) => {
      throw error
    })
  }

  const memoizedMarkedColumns = useMemo(() => {
    return (
      selectedColumnsByDataset: Record<string, string[]>,
      selectInputPayloadDatasets: Array<IProcessedData>,
    ) => {
      selectInputPayloadDatasets.forEach((dataset) => {
        const datasetId = dataset.id

        if (selectedColumnsByDataset[datasetId]) {
          const selectedColumnIds = selectedColumnsByDataset[datasetId]

          dataset.columns.forEach((column) => {
            if (selectedColumnIds.includes(column.id)) {
              column.selected = true
            }
          })
        }
      })
    }
  }, [])

  const handleSubmitNameStep = (
    payload: Pick<IWarehouse, 'name' | 'description' | 'refresh'>,
  ) => {
    handleUpdateCurrentDataset({
      name: payload.name,
      description: payload.description,
      refresh: payload.refresh,
    })
    nextStepAndOffLoading()
  }

  const handleSubmitSelectInput = (payload: {
    datasets: Array<IProcessedData>
  }) => {
    memoizedMarkedColumns(selectedColumnsByDataset, payload.datasets)

    const warehouseBuildDataset = new WarehouseBuildDataset(payload.datasets)
    handleUpdateCurrentDataset({
      sources: warehouseBuildDataset.buildDataset(),
    })

    nextStepAndOffLoading()
  }

  const handleSubmitQuestionAndKnowledge = (
    payload: Pick<IWarehouse, 'questions' | 'knowledge'>,
  ) => {
    handleUpdateCurrentDataset({
      questions: payload.questions,
      knowledge: payload.knowledge,
    })
    const promise = onLoadWarehouseToServer({
      ...warehouseTable,
      questions: payload.questions,
      knowledge: payload.knowledge,
    })
      .then(() => {
        nextStepAndOffLoading()
        setWarehouseTableName('')
        setSelectedColumnsByDataset({})
      })
      .catch(() => {
        setIsSubmitting(false)
        throw new Error('Error when create warehouse')
      })

    toast.promise(promise, {
      pending: t('processingWarehouse'),
      success: t('createWarehouseSuccessfully'),
      error: t('errorCreateWarehouse'),
    })
    return
  }

  const nextStepAndOffLoading = () => {
    handleNextStep()
    setIsSubmitting(false)
  }

  const onSubmit = props.handleSubmit(async (payload) => {
    setIsSubmitting(true)
    switch (currentStep) {
      case DATA_WAREHOUSE_CREATE_STEP.NAME:
        handleSubmitNameStep(
          payload as Pick<IWarehouse, 'name' | 'description' | 'refresh'>,
        )
        return
      case DATA_WAREHOUSE_CREATE_STEP.SELECT_INPUT:
        handleSubmitSelectInput(payload as { datasets: IProcessedData[] })
        return

      case DATA_WAREHOUSE_CREATE_STEP.QUESTION_AND_KNOWLEDGE:
        handleSubmitQuestionAndKnowledge(
          payload as Pick<IWarehouse, 'knowledge' | 'questions'>,
        )
        return
    }
  })

  return {
    isSubmitting,
    isWarehouseLimitReached,
    isDataWarehouseEnabled,
    form: props,
    onSubmit,
    currentStep,
    handlePreStep,
  }
}
