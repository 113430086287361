import { toast } from 'react-toastify'

import { connectDB } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'
import { useToggle } from '@/hooks/useToggle'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import { DB_CONNECT_TYPE } from '../../../dataset.constant'
import { UpdateDatabaseConnectionProps } from './updateDatabaseConnect.props'

const useDatabaseConnection = (props: UpdateDatabaseConnectionProps) => {
  const { form } = props
  const { params } = useRouter()
  const pid = params.pid as string

  const { t } = useTrans()
  const dispatch = useAppDispatch()
  const { toggle: isShowTables, handleToggle: handleToggleShowTables } =
    useToggle()

  const currentConnectType = form.watch('dbConfig.dbType')

  const handleConnectionDatabase = async () => {
    const name = form.getValues('name')
    const description = form.getValues('description')
    let dbConfig: ANY = {
      name,
      description,
    }

    if (currentConnectType === DB_CONNECT_TYPE.BIG_QUERY) {
      const gcp_project_id = form.getValues('gcp_project_id')
      const gcp_dataset_id = form.getValues('gcp_dataset_id')
      const gcp_credentials = form.getValues('gcp_credentials')

      dbConfig = {
        ...dbConfig,
        gcp_project_id,
        gcp_dataset_id,
        credentials: JSON.parse(gcp_credentials as string),
        type: DB_CONNECT_TYPE.BIG_QUERY,
      }
    } else if (currentConnectType === DB_CONNECT_TYPE.SALESFORCE) {
      const user = form.getValues('user')
      const pwd = form.getValues('pwd')
      const security_token = form.getValues('security_token')

      dbConfig = {
        ...dbConfig,
        user,
        pwd,
        security_token,
        type: DB_CONNECT_TYPE.SALESFORCE,
      }
    } else {
      const _dbConfig = form.getValues('dbConfig')
      dbConfig = {
        ...dbConfig,
        ..._dbConfig,
      }
    }

    // // CONNECT TO DATABASE
    const promise = dispatch(connectDB(pid, dbConfig))
    toast.promise(promise, {
      pending: t('processingInputData'),
      success: t('successMessage'),
      error: t('errorMessageSettingInputData'),
    })
    promise.then((result: ANY) => {
      form.setValue('tables', result.tables)
      handleToggleShowTables()
      // TODO: Update to get the whole project instead of tables
    })
  }

  return {
    currentConnectType,
    isShowTables,
    handleConnectionDatabase,
  }
}

export default useDatabaseConnection
