import { useTrans } from '@/hooks/useTranslation'
import { useWarningBeforeLeave } from '@/hooks/useValidateCreateDatasetBeforeLeave'

import { DB_CONNECT_TYPE } from '../../dataset.constant'
import { useDatasetCreateContext } from '../datasetCreate.context'
import { useUpdateConnectionContext } from './updateConnection.context'
import { UpdateConnectionProps } from './updateConnection.props'

export const useUpdateConnection = (props: UpdateConnectionProps) => {
  const { form } = props
  const { t } = useTrans()
  const { currentConnectType, setConnectType } = useUpdateConnectionContext()
  const { dataset, handleUpdateCurrentDataset } = useDatasetCreateContext()
  useWarningBeforeLeave({
    validate: () => {
      const host = form.getValues('host')
      const dbType = form.getValues('dbType')
      const port = form.getValues('port')
      const dbName = form.getValues('dbName')
      const user = form.getValues('user')
      const pwd = form.getValues('pwd')
      return (
        host ||
        dbType ||
        port ||
        dbName ||
        user ||
        pwd ||
        Object.keys(dataset).length > 0
      )
    },
  })

  const handleChangeConnectionType = (value: DB_CONNECT_TYPE) => {
    setConnectType(value)
    form.reset()

    if (value === DB_CONNECT_TYPE.BIG_QUERY) {
      form.setValue('type', value)
      return
    }
    if (value === DB_CONNECT_TYPE.SALESFORCE) {
      form.setValue('type', value)
      return
    }

    const databasePort = {
      [DB_CONNECT_TYPE.MYSQL]: 3306,
      [DB_CONNECT_TYPE.POSTGRES_SQL]: 5432,
      [DB_CONNECT_TYPE.ORACLE]: 1521,
      [DB_CONNECT_TYPE.SQL_SERVER]: '',
    }
    form.setValue('port', databasePort[value])
    form.setValue('dbType', value)
  }

  const isCheckedRefresh = dataset?.refresh ?? false

  return {
    t,
    form,
    isCheckedRefresh,
    currentConnectType,
    handleUpdateCurrentDataset,
    handleChangeConnectionType,
  }
}
