import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { confirmRodal } from '@/components/rodal'
import Rodal from '@/components/rodal/rodal'
import { ELT_POLLING_STATUS } from '@/constants/common'
import { deleteInputData, setInputData } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { useWarningBeforeLeave } from '@/hooks/useValidateCreateDatasetBeforeLeave'

import {
  UseDatasetUpdateProps,
  UseValidateLeavePage,
} from './datasetUpdateLayout.props'

const useFormDatasetUpdate = ({
  schema,
  defaultValues = {},
  cbSubmit,
}: UseDatasetUpdateProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTrans()
  const { params } = useRouter()
  const pid = params.pid

  const props = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const [refresh, setRefresh] = useState<boolean>(true)

  const handleCancel = () => {
    props.reset(defaultValues)
  }

  const onUpdateDatasetToServer = useCallback(
    (payload: Record<string, unknown>) => {
      if (!pid) {
        throw new Error('Project ID is not defined')
      }

      const _payload: Record<string, unknown> = { ...payload }
      const promise = dispatch(setInputData(pid, _payload, refresh))
      toast.promise(promise, {
        pending: t('processingData'),
        success: t('tableAddToProject'),
        error: t('errorCreateDataset'),
      })
      return promise
    },
    [pid, refresh, dispatch, t],
  )
  const onSubmit = props.handleSubmit(async (payload) => {
    onUpdateDatasetToServer(payload).then(() => {
      cbSubmit && cbSubmit(payload)
    })
  })

  const isView = !props.formState.isDirty // isDirty: true when user change value of input
  const isDisabled = ELT_POLLING_STATUS.includes(defaultValues?.etl?.status)

  return {
    isView: isView,
    form: props,
    isDisabled,
    onSubmit,
    setRefresh,
    handleCancel,
  }
}

export const useRemoveDataset = () => {
  const { t } = useTrans()
  const dispatch = useAppDispatch()

  const { params, navigate } = useRouter()
  const { pid, id } = params

  const handleRemoveDataset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    confirmRodal({
      children: ({ onClose }) => {
        return (
          <Rodal
            message={t('descriptionDeleteDataset')}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={() => {
              const payload = { iid: id! }
              dispatch(deleteInputData(pid!, payload)).then(() => {
                navigate(`/project/${pid}/dataset`)
              })
            }}
            title={t('titleDeleteDataset')}
          />
        )
      },
    })
  }

  return { handleRemoveDataset }
}

export const useValidateLeavePage = (props: UseValidateLeavePage) => {
  const { isView } = props
  const validate = () => {
    return !isView
  }

  const { blocker } = useWarningBeforeLeave({
    validate,
  })

  return { blocker }
}
export default useFormDatasetUpdate
