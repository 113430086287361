import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { BASE_URL } from '@/constants/common'
import { useSession } from '@/contexts/sessionContext'
import { authenticate } from '@/helpers/auth'
import ConsoleHelper from '@/helpers/ConsoleHelper'
import axios from '@/services/http/axiosInstance'

const useAuth = () => {
  const [isShowGoogleLoginButton, setShowGoogleLoginButton] = useState(true)
  const { setSession } = useSession()
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseGoogle = (response?: any) => {
    axios
      .post(`${BASE_URL}/backend/googlelogin`, {
        idToken: response.credential,
      })
      .then((res) => {
        ConsoleHelper('Authentication Success:' + res.data.user.email)
        authenticate(res, () => {
          setSession(true)
          toast.success(t('welcomeBack', { name: res.data.user.name }))
        })
      })
      .catch((err) => {
        if (err.response)
          if (err.response.data)
            if (err.response.data.errors) toast.error(err.response.data.errors)
            else
              toast.error(
                t('serverConnectionError') +
                  err.response.status +
                  ' ' +
                  err.response.statusText,
              )
          else
            toast.error(
              t('serverConnectionError') +
                err.response.status +
                ' ' +
                err.response.statusText,
            )
        else toast.error(t('serverConnectionErrorSupport'))
      })
  }

  return { responseGoogle, isShowGoogleLoginButton, setShowGoogleLoginButton }
}

export default useAuth
