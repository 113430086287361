import clsx from 'clsx'

import { Checkbox } from '@/components/ui/checkbox'
import { useTrans } from '@/hooks/useTranslation'

import { RowCheckboxProps } from './rowCheckbox.props'

const RowCheckbox = (props: RowCheckboxProps) => {
  const { t } = useTrans()
  const isTheRowSelectALL = props.isRowSelectALL
  const classNameContainer = clsx(
    'py-4 px-6 border rounded-lg border-border-1 space-y-4 w-full',
    {
      '!border-0': isTheRowSelectALL,
      '!gap-2': isTheRowSelectALL,
    },
    props.className ?? '',
  )
  const classNameLabel = clsx('w-full flex gap gap-6 cursor-pointer', {
    '!border-0': isTheRowSelectALL,
    '!gap-2': isTheRowSelectALL,
  })

  return (
    <div className={classNameContainer}>
      <label className={classNameLabel} onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={props.checked}
          className="mt-1"
          onChange={(e) => e.stopPropagation()}
          onCheckedChange={props.onChange}
          onClick={(e) => e.stopPropagation()}
        />
        <div className=" flex justify-between gap-1">
          <div className="flex flex-col gap-2 select-none">
            <p>{props.label}</p>
            {props?.description && (
              <p>
                <span>{t('description')}: </span>
                {props.description}
              </p>
            )}
          </div>
        </div>
      </label>
    </div>
  )
}

export default RowCheckbox
