import { CheckedState } from '@radix-ui/react-checkbox'
import React, { ReactNode, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { browserCacheKey, MAXIMUM_SELECT_HEADER } from '@/constants/common'
import useBrowserCache from '@/hooks/useBrowserCache'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import {
  ANY,
  IProcessedData,
  IProcessedDataColumn,
  IProjectInputDataWithInputId,
} from '@/types'

import { useDataWarehouseCreateContext } from '../../../dataWarehouseCreate.context'
import { IIsCheckedAll } from '../../../dataWarehouseCreate.props'

interface TableFormFactoryCtxProps {
  isCheckedAll: IIsCheckedAll
  selectedColumnsByDataset: Record<string, string[]>
  handleUpdateAllColumnsChecked: (
    _datasetId: string,
    _checked: CheckedState,
  ) => void
  handleUpdateColumnsChecked: (
    _datasetId: string,
    _colId: string,
    _checked: boolean,
  ) => void
}

export const TableFormFactoryCtx =
  React.createContext<TableFormFactoryCtxProps | null>(null)

export const countSelectedColumns = (columns: Array<IProcessedDataColumn>) => {
  return columns.reduce((count: number, col: IProcessedDataColumn) => {
    return col.selected ? count + 1 : count
  }, 0)
}

export const TableFormFactoryProvider = ({
  children,
  form,
  dataSource = 'datasets',
}: {
  children: ReactNode
  form: ANY
  dataSource?: 'datasets' | 'sources'
}) => {
  const { t } = useTrans()
  const { params } = useRouter()
  const { pid, id } = params

  const datasets: IProcessedData[] = form.getValues(dataSource)
  const { insertOrUpdateCacheData } = useBrowserCache(browserCacheKey.tables)

  const [isCheckedAll, setIsCheckedAll] = useState<IIsCheckedAll>({})
  const { selectedColumnsByDataset, setSelectedColumnsByDataset } =
    useDataWarehouseCreateContext()

  useEffect(() => {
    function areAllColumnsSelected(
      datasets: IProcessedData[],
      selectedColumnsByDataset: Record<string, string[]>,
    ) {
      return Object.keys(selectedColumnsByDataset).forEach((datasetId) => {
        const dataset = datasets.find((d) => d.id === datasetId)

        if (!dataset) return false

        const datasetColumns = dataset.columns.map((column) => column.id)

        const selectedColumns = selectedColumnsByDataset[datasetId]

        const allSelected = datasetColumns.every((columnId) =>
          selectedColumns.includes(columnId),
        )
        setIsCheckedAll((prevState) => ({
          ...prevState,
          [datasetId]: allSelected,
        }))
      })
    }

    areAllColumnsSelected(datasets, selectedColumnsByDataset)
  }, [datasets, selectedColumnsByDataset])

  const updateCacheWarehouseWhenSourceChange = (
    data: IProjectInputDataWithInputId[],
  ) => {
    if (dataSource === 'sources') {
      insertOrUpdateCacheData({
        where: {
          pid: pid,
          warehouseId: id,
          source: 'update_basic',
        },
        data: {
          [dataSource]: data,
        },
      })
    }
  }

  const handleUpdateAllColumnsChecked = (
    datasetId: string,
    checked: CheckedState,
  ) => {
    const datasets = form.getValues(dataSource)
    const dataset: IProcessedData = datasets.find(
      (d: IProcessedData) => d.id === datasetId,
    )

    const countSelectedCol = countSelectedColumns(dataset.columns)

    let avlCountCheck = MAXIMUM_SELECT_HEADER - countSelectedCol
    dataset.columns = dataset.columns.map((col: IProcessedDataColumn) => {
      if (checked) {
        if (avlCountCheck > 0) {
          col.selected = true
          avlCountCheck--
        }
      } else {
        col.selected = false
      }
      return col
    }, [])

    form.setValue(dataSource, datasets, { shouldDirty: true })
    updateCacheWarehouseWhenSourceChange(datasets)

    setIsCheckedAll({ ...isCheckedAll, [datasetId]: checked as boolean })

    const selectedCols = dataset.columns
      .filter((col) => col.selected)
      .map((col) => col.id)
    setSelectedColumnsByDataset({
      ...selectedColumnsByDataset,
      [datasetId]: selectedCols,
    })
  }

  const handleUpdateColumnsChecked = (
    datasetId: string,
    colId: string,
    checked: boolean,
  ) => {
    const datasets = form.getValues(dataSource)
    const dataset: IProcessedData = datasets.find(
      (d: ANY) => d.id === datasetId,
    )

    let updatedSelectedColumns = selectedColumnsByDataset[datasetId] || []
    if (checked) {
      updatedSelectedColumns = [...updatedSelectedColumns, colId]
    } else {
      updatedSelectedColumns = updatedSelectedColumns.filter(
        (id) => id !== colId,
      )
    }

    setSelectedColumnsByDataset({
      ...selectedColumnsByDataset,
      [datasetId]: updatedSelectedColumns,
    })

    let selectedColumn: IProcessedDataColumn | undefined
    dataset.columns = dataset.columns.map((col: IProcessedDataColumn) => {
      if (col.id === colId) {
        selectedColumn = {
          ...col,
          selected: checked,
        }
        return selectedColumn
      }
      return col
    })

    const countSelectedCol = countSelectedColumns(dataset.columns)
    if (
      selectedColumn?.selected === false &&
      countSelectedCol >= MAXIMUM_SELECT_HEADER
    ) {
      toast.warning(
        t('limitSelectedInputData', { limit: MAXIMUM_SELECT_HEADER }),
      )

      if (!isCheckedAll[datasetId]) {
        isCheckedAll[datasetId] = true
        setIsCheckedAll({ ...isCheckedAll })
      }

      return
    }

    if (checked) {
      if (
        countSelectedCol >= MAXIMUM_SELECT_HEADER - 1 ||
        countSelectedCol === dataset.columns.length
      ) {
        isCheckedAll[datasetId] = true
        setIsCheckedAll({ ...isCheckedAll })

        form.setValue(dataSource, datasets, { shouldDirty: true })
        updateCacheWarehouseWhenSourceChange(datasets)
      }
    }

    if (
      !checked &&
      countSelectedCol - 1 < MAXIMUM_SELECT_HEADER &&
      isCheckedAll[datasetId]
    ) {
      isCheckedAll[datasetId] = false
      setIsCheckedAll({ ...isCheckedAll })
    }

    form.setValue(dataSource, datasets, { shouldDirty: true })
    updateCacheWarehouseWhenSourceChange(datasets)

    const selectedCols = dataset.columns
      .filter((col) => col.selected)
      .map((col) => col.id)
    setSelectedColumnsByDataset({
      ...selectedColumnsByDataset,
      [datasetId]: selectedCols,
    })
  }

  return (
    <TableFormFactoryCtx.Provider
      value={{
        isCheckedAll,
        handleUpdateAllColumnsChecked,
        handleUpdateColumnsChecked,
        selectedColumnsByDataset,
      }}
    >
      {children}
    </TableFormFactoryCtx.Provider>
  )
}

export const useTableFormFactoryContext = () => {
  const ctx = React.useContext(TableFormFactoryCtx)
  if (!ctx)
    throw new Error(
      'TableFormFactoryCtx must be used within a TableFormFactoryProvider',
    )

  return ctx
}

export default TableFormFactoryCtx
