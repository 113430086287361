import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  connectDB,
  getProjects,
  getWarehouseStatus,
} from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { EETLStatus } from '@/types'

export const useGetDatasetProject = () => {
  const ref_scrollElement = useRef(null)
  const { params, navigate } = useRouter()
  const { pid, id } = params
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getProjects({}))
  }, [dispatch])

  useEffect(() => {
    if (ref_scrollElement.current) {
      ;(ref_scrollElement.current as HTMLElement).scrollTo(0, 0)
    }
  }, [id])

  const { project } = useGetProject({ pid: pid! })
  const isEmptyProject = Boolean(project)
  const isEmptyQuestion = Boolean(project?.questions?.length)

  const handleDatasetClick = useCallback(
    async (key: string, type: string) => {
      setLoading(true)
      dispatch(getWarehouseStatus(pid!))

      if (type === 'database') {
        const data = { iid: key }
        await dispatch(connectDB(pid!, data))
      }
      setLoading(false)
      navigate(`/project/${pid}/dataset/${key}`)
    },
    [dispatch, navigate, pid],
  )

  const datasets = useMemo(() => {
    const datasetEntries = Object.entries(project?.inputData ?? {})

    return datasetEntries.reduce(
      (acc, [key, value]) => {
        acc.push({
          key: key,
          label: value.name,
          status: value?.etl?.status,
          onClick: () => handleDatasetClick(key, value.type),
        })
        return acc
      },
      [] as {
        key: string
        label: string
        onClick: () => void
        status: EETLStatus
      }[],
    )
  }, [handleDatasetClick, project?.inputData])

  const firstDataset = useMemo(() => {
    const datasetEntries = Object.values(project?.inputData ?? {})
    return datasetEntries?.[0] ?? null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(project)])

  const goToCreateDataset = () => {
    navigate(`/project/${pid}/dataset/create`)
  }

  const meta = {
    pid,
    id,
    project,
    firstDataset,
    datasets,
    isEmptyProject,
    isEmptyQuestion,
    ref_scrollElement,
    goToCreateDataset,
    loading,
  }

  return meta
}
