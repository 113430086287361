import { jwtDecode } from 'jwt-decode'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { BASE_URL } from '@/constants/common'
import { useSession } from '@/contexts/sessionContext'
import { authenticate } from '@/helpers/auth'
import ConsoleHelper from '@/helpers/ConsoleHelper'
import axios from '@/services/http/axiosInstance'

const useActive = () => {
  const { setSession } = useSession()

  const { t } = useTranslation()

  const params = useParams()
  const [formData, setFormData] = useState({
    name: '',
    token: '',
    show: true,
  })

  const { name, token } = formData

  useEffect(() => {
    const token = params.token
    if (token) {
      const decodedToken = jwtDecode(token)

      setFormData((prevForm) => ({
        ...prevForm,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name: (decodedToken as any).name,
        token,
      }))
    }
  }, [params])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    ConsoleHelper('In handle submit')
    axios
      .post(`${BASE_URL}/backend/activation`, {
        token,
      })
      .then((res) => {
        setFormData((prevForm) => ({ ...prevForm, show: false }))

        ConsoleHelper('Successfull activation')
        toast.success(t('activatedSuccessfully'))
        authenticate(res, () => {
          setFormData((prevForm) => ({
            ...prevForm,
            email: '',
            password1: '',
            textChange: 'Submitted',
          }))
          setSession(true)
          toast.success(t('welcomeBack', { name: res.data.user.name }))
        })
      })
      .catch((err) => {
        if (err.response.data.errors) toast.error(err.response.data.errors)
        else
          toast.error(
            t('serverConnectionError') +
              err.response.status +
              ' ' +
              err.response.statusText,
          )
      })
  }

  return {
    name,
    handleSubmit,
  }
}

export default useActive
