import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

import { IconTooltipProps } from './iconTooltip.props'

const IconTooltip = (props: IconTooltipProps) => {
  const { icon, message, disabled, className } = props
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger disabled={disabled}>{icon}</TooltipTrigger>
        <TooltipContent className={className}>{message}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default IconTooltip
