import { PersonIcon } from '@radix-ui/react-icons'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input, InputPassword } from '@/components/ui/input'

import useRegister from './Register.hook'

const Register = () => {
  const { t } = useTranslation()
  const {
    isAcceptPrivacy,
    form,
    buttonText,
    handleChange,
    handleSubmit,
    setAcceptPrivacy,
  } = useRegister()

  return (
    <Form {...form}>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="space-y-[16px]">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="caption2" requiredField>
                    {t('name')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="h-[40px]"
                      onChange={handleChange('name')}
                      placeholder={t('name')}
                      type="text"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="caption2" requiredField>
                    {t('email')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="h-[40px]"
                      onChange={handleChange('email')}
                      placeholder={t('email')}
                      type="email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="company"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="caption2" requiredField>
                    {t('company')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="h-[40px]"
                      onChange={handleChange('company')}
                      placeholder={t('company')}
                      type="text"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="password1"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="caption2" requiredField>
                    {t('password')}
                  </FormLabel>
                  <FormControl>
                    <InputPassword
                      type="password"
                      {...field}
                      className="h-[40px]"
                      placeholder={t('password')}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="password2"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="caption2" requiredField>
                    {t('confirmPassword')}
                  </FormLabel>
                  <FormControl>
                    <InputPassword
                      type="password"
                      {...field}
                      className="h-[40px]"
                      placeholder={t('confirmPassword')}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
        </div>
        <div className="flex space-x-2 mt-[48px]">
          <Checkbox
            checked={isAcceptPrivacy}
            className="mt-1"
            id="isAcceptPolicy"
            onClick={() => setAcceptPrivacy(!isAcceptPrivacy)}
          />
          <label
            className="caption1 font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            dangerouslySetInnerHTML={{
              __html: t('termsAndPrivacyRegister', {
                termsLink:
                  // eslint-disable-next-line max-len
                  'https://webapp-terms-condition-privacypolicy.s3.amazonaws.com/TERAMOT+-+Terms+and+Conditions.pdf',
                privacyLink:
                  'https://webapp-terms-condition-privacypolicy.s3.amazonaws.com/Teramot+-+PrivacyPol.pdf',
                className: 'text-text-blue',
              }),
            }}
            htmlFor="isAcceptPolicy"
          ></label>
        </div>
        <div className="flex justify-center mt-[53px]">
          <Button
            className="min-w-[180px]"
            disabled={!isAcceptPrivacy}
            type="submit"
            variant="default"
          >
            <PersonIcon className="mr-2.5 h-4 w-4" /> {buttonText}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default Register
